import React, { useState, useEffect } from "react";

// react plugin used to create charts
import { Bar } from "react-chartjs-2";
import classNames from "classnames";
import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  ButtonGroup,
} from "reactstrap";
import { getActiveUser } from "actions/dashbaordAction";
export const ActiveUsers = () => {
  let dashboardData = useSelector((state) => state.dashboardData);
  const { loading } = dashboardData;
  const alert = useAlert();
  const dispatch = useDispatch();
  const [charData, setcharData] = useState(null);
  const [bigChartData, setbigChartData] = React.useState("w");

  let filters = [
    {
      name: "All Now",
      key: "t",
    },
    {
      name: "Weekly",
      key: "w",
    },
    {
      name: "Monthly",
      key: "m",
    },
  ];

  const genRateChartData = (incoming) => {
    return {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
        gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
        gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

        return {
          labels: incoming.label,
          datasets: [
            {
              label: "Users",
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: "#1f8ef1",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: incoming.y,
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 120,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        },
      },
    };
  };
  const formateData = (type, incoming) => {
    let resp = { y: [], label: [] };
    if (type === "m" || type === "w") {
      resp.label = incoming.map((e) => e._id);
      resp.y = incoming.map((e) => e.count);
    } else {
      resp.y = [
        parseInt(incoming[0].averageDailyCount) || 0,
        parseInt(incoming[0].averageMonthlyCount) || 0,
      ];
      resp.label = ["Daily", "Monthly"];
    }
    return resp;
  };

  useEffect(() => {
    dispatch(getActiveUser({ dateFilter: bigChartData, userId: null }))
      .then((resp) => {
        console.log(resp);
        let formatedData = formateData(bigChartData, resp);
        let data = genRateChartData(formatedData);
        setcharData(data);
      })
      .catch((err) => {
        alert.error(typeof err.message === "string" ? err.message : "");
      });
  }, [bigChartData]);
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col sm="6">
                    <h5 className="card-category">Active Users</h5>
                    <CardTitle tag="h3"></CardTitle>
                  </Col>
                  <Col sm="6">
                    {" "}
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      {filters.map((e) => {
                        return (
                          <Button
                            key={e.key}
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === e.key,
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setbigChartData(e.key)}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              {e.name}
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {loading && (
                  <div className="d-flex justify-content-center align-items-center">
                    <Circles
                      height="80"
                      width="80"
                      color="#1f8ef1"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                )}
                {charData && !loading && (
                  <div className="chart-area">
                    <Bar data={charData.data} options={charData.options} />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
