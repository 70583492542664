import axios from "axios";

import Config from "../Config.js";
import {
  GET_PROFILE_VIEWS,
  GET_FAV_COUNT,
  GET_CONNECTIONS,
  GET_USER,
  GET_INBOUND_OUTBOUND,
  GET_ACTIVE_USERS,
} from "../Constant";

export const getProfileViews = (body) => async (dispatch) => {
  return new Promise(async (resolve, resject) => {
    const userToken = localStorage.getItem("token");
    try {
      let config = {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      };
      dispatch({
        type: GET_PROFILE_VIEWS + "REQUEST",
      });
      let { data } = await axios.post(
        `${Config.BASE_URL}${Config.GET_PROFILE_VIWES}`,
        body,
        config
      );

      dispatch({
        type: GET_PROFILE_VIEWS + "SUCCESS",
        payload: data.data,
      });
      resolve(data.data);
    } catch (error) {
      error.message = error?.response?.data?.message || "Network Issue";
      dispatch({
        type: GET_PROFILE_VIEWS + "FAILED",
        payload: error.message,
      });

      resject(error);
    }
  });
};

export const getFavCount = (body) => async (dispatch) => {
  return new Promise(async (resolve, resject) => {
    const userToken = localStorage.getItem("token");
    try {
      let config = {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      };
      dispatch({
        type: GET_FAV_COUNT + "REQUEST",
      });
      let { data } = await axios.post(
        `${Config.BASE_URL}${Config.GET_FAV_COUNT}`,
        body,
        config
      );

      dispatch({
        type: GET_FAV_COUNT + "SUCCESS",
        payload: data.data,
      });
      resolve(data.data);
    } catch (error) {
      error.message = error?.response?.data?.message || "Network Issue";
      dispatch({
        type: GET_FAV_COUNT + "FAILED",
        payload: error.message,
      });

      resject(error);
    }
  });
};

export const getConnections = (body) => async (dispatch) => {
  return new Promise(async (resolve, resject) => {
    const userToken = localStorage.getItem("token");
    try {
      let config = {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      };
      dispatch({
        type: GET_CONNECTIONS + "REQUEST",
      });
      let { data } = await axios.post(
        `${Config.BASE_URL}${Config.GET_CONNECTIONS}`,
        body,
        config
      );

      dispatch({
        type: GET_CONNECTIONS + "SUCCESS",
        payload: data.data,
      });
      resolve(data.data);
    } catch (error) {
      error.message = error?.response?.data?.message || "Network Issue";
      dispatch({
        type: GET_CONNECTIONS + "FAILED",
        payload: error.message,
      });

      resject(error);
    }
  });
};

export const getUser = (body) => async (dispatch) => {
  return new Promise(async (resolve, resject) => {
    const userToken = localStorage.getItem("token");
    try {
      let config = {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      };
      dispatch({
        type: GET_USER + "REQUEST",
      });
      let { data } = await axios.post(
        `${Config.BASE_URL}${Config.GET_USER}`,
        body,
        config
      );

      dispatch({
        type: GET_USER + "SUCCESS",
        payload: data.data,
      });
      resolve(data.data);
    } catch (error) {
      error.message = error?.response?.data?.message || "Network Issue";
      dispatch({
        type: GET_USER + "FAILED",
        payload: error.message,
      });

      resject(error);
    }
  });
};
export const getInboundOutbound = (body) => async (dispatch) => {
  return new Promise(async (resolve, resject) => {
    const userToken = localStorage.getItem("token");
    try {
      let config = {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      };
      dispatch({
        type: GET_INBOUND_OUTBOUND + "REQUEST",
      });
      let { data } = await axios.post(
        `${Config.BASE_URL}${Config.GET_INBOUND_OUTBOUND}`,
        body,
        config
      );

      dispatch({
        type: GET_INBOUND_OUTBOUND + "SUCCESS",
        payload: data.data,
      });
      resolve(data.data);
    } catch (error) {
      error.message = error?.response?.data?.message || "Network Issue";
      dispatch({
        type: GET_INBOUND_OUTBOUND + "FAILED",
        payload: error.message,
      });

      resject(error);
    }
  });
};

export const getActiveUser = (body) => async (dispatch) => {
  return new Promise(async (resolve, resject) => {
    const userToken = localStorage.getItem("token");
    try {
      let config = {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      };
      dispatch({
        type: GET_ACTIVE_USERS + "REQUEST",
      });
      let { data } = await axios.post(
        `${Config.BASE_URL}${Config.GET_ACTIVE_USERS}`,
        body,
        config
      );

      dispatch({
        type: GET_ACTIVE_USERS + "SUCCESS",
        payload: data.data,
      });
      resolve(data.data);
    } catch (error) {
      error.message = error?.response?.data?.message || "Network Issue";
      dispatch({
        type: GET_ACTIVE_USERS + "FAILED",
        payload: error.message,
      });

      resject(error);
    }
  });
};
