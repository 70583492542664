module.exports = Object.freeze({
  BASE_URL: process.env.REACT_APP_API_ENDPOINT,
  S3_PREFIX:
    "https://connecttherelevantstaging.s3.ap-south-1.amazonaws.com/userpic/",
  GET_OTP: "auth/login",
  VERIFY_OTP: "talent/webLoginVerifyOTp",
  GET_CATEGORY: "category/list",
  GET_PROFILE_VIWES: "dashboard/graphprofileview",
  GET_FAV_COUNT: "analytics/getFavourate",
  GET_USER_DATA: "user/userProfile/",
  PROFILE_UPDATE: "profilerequest",
  GET_USER_NOTIFICATION: "usernotification",
  SEND_PROFILE_SENT_OTP: "artiste/profileVerifyOtpSent",
  SEND_PROFILE_VERIFY_OTP: "artiste/profileVerifyOtpVerify",
  ADD_MANAGER: "artiste/addManager",
  UPDATE_MANAGER: "artiste/updateManager",
  DELETE_MANAGER: "artiste/deleteManager",
  DELETE_SOCIAL_LINK: "artiste/deleteSocialLink",
  UPDATE_SOCIAL_LINK: "artiste/updateSocialLink",
  ADD_SOCIAL_LINK: "artiste/addSocialLink",
  GET_CONNECTIONS: "analytics/connection",
  GET_USER: "analytics/getUser",
  GET_INBOUND_OUTBOUND: "analytics/getInboundOutbound",
  GET_ACTIVE_USERS: "analytics/getActiveUsers",
});
