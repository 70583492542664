import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { chart1_2_options } from "variables/charts.js";
import classNames from "classnames";
import { Line } from "react-chartjs-2";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "actions/dashbaordAction";
export const User = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  let dashboardData = useSelector((state) => state.dashboardData);
  const { userData } = dashboardData;
  const [connectionChartData, setconnectionChartData] = useState(null);
  let filters = [
    {
      name: "Today",
      key: "t",
    },
    {
      name: "Weekly",
      key: "w",
    },
    {
      name: "Monthly",
      key: "m",
    },
    {
      name: "Till Now",
      key: "a",
    },
  ];
  const genRateChartData = (incoming, flag) => {
    return {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
        // 9a9a9a
        return {
          labels: incoming?.allLabels ?? [],
          datasets: [
            {
              label: "Artist",
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#1f8ef1", //line
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#1f8ef1",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1f8ef1",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: incoming.talentData?.yAxis?.[0]?.data ?? [],
            },
            {
              label: "Manager",
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#e1ad01", //line
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#e1ad01",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#e1ad01",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: incoming.managerData?.yAxis?.[0]?.data ?? [],
            },
            {
              label: "Client",
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#00A86B", //line
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#00A86B",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#00A86B",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: incoming.clientData?.yAxis?.[0]?.data ?? [],
            },
          ],
        };
      },
    };
  };
  const [bigChartData, setbigChartData] = React.useState("m");
  const forMateData = (input) => {
    // console.log(input);
    let allLabels = [
      ...new Set(
        [
          ...input?.respClient?.xAxis,
          ...input?.respTalent?.xAxis,
          ...input?.respManager?.xAxis,
        ].sort()
      ),
    ].sort();

    if (bigChartData === "m") allLabels = input.allLabels ?? [];
    let talentData = { xAxis: [], yAxis: [{ data: [] }] };
    let managerData = { xAxis: [], yAxis: [{ data: [] }] };
    let clientData = { xAxis: [], yAxis: [{ data: [] }] };
    for (let index = 0; index < allLabels.length; index++) {
      const element = allLabels[index];
      if (input?.respClient?.xAxis.includes(element)) {
        let index = input?.respClient?.xAxis.indexOf(element);
        clientData.yAxis[0].data.push(input.respClient.yAxis[0].data[index]);
      } else {
        clientData.yAxis[0].data.push(0);
      }
      if (input?.respTalent?.xAxis.includes(element)) {
        let index = input?.respTalent?.xAxis.indexOf(element);
        talentData.yAxis[0].data.push(input.respTalent.yAxis[0].data[index]);
      } else {
        talentData.yAxis[0].data.push(0);
      }
      if (input?.respManager?.xAxis.includes(element)) {
        let index = input?.respManager?.xAxis.indexOf(element);
        managerData.yAxis[0].data.push(input.respManager.yAxis[0].data[index]);
      } else {
        managerData.yAxis[0].data.push(0);
      }
    }
    return {
      allLabels,
      talentData,
      managerData,
      clientData,
    };
  };
  useEffect(() => {
    dispatch(getUser({ dateFilter: bigChartData, userId: null }))
      .then((resp) => {})
      .catch((err) => {
        alert.error(typeof err.message === "string" ? err.message : "");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bigChartData]);
  useEffect(() => {
    if (userData) {
      let formatedData = forMateData(userData);
      // console.log(formatedData);
      let chartData = genRateChartData(formatedData);
      setconnectionChartData(chartData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="content">
      {connectionChartData ? (
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">User</h5>
                    {/* <CardTitle tag="h2">Performance</CardTitle> */}
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      {filters.map((e) => {
                        return (
                          <Button
                            key={e.key}
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === e.key,
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setbigChartData(e.key)}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              {e.name}
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={connectionChartData.data}
                    options={chart1_2_options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
};
