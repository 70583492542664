export const GET_OTP = "GET_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const ASSIGN_AGE_AND_GENDER = "ASSIGN_AGE_AND_GENDER";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_QUESTION = "GET_QUESTION";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_TO_CART = "ADD_TO_CART";
export const CHECKOUT = "CHECKOUT";
export const GET_COUPON = "GET_COUPON";
export const SET_ANSWERS = "SET_ANSWERS";
export const GET_PROFILE_VIEWS = "GET_PROFILE_VIEWS";
export const GET_FAV_COUNT = "GET_FAV_COUNT";
export const GET_USER = "GET_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const SEND_PROFILE_VERIFY_OTP = "SEND_PROFILE_VERIFY_OTP";
export const SEND_PROFILE_SENT_OTP = "SEND_PROFILE_SENT_OTP";
export const ADD_MANAGER = "ADD_MANAGER";
export const UPDATE_MANAGER = "UPDATE_MANAGER";
export const DELETE_MANAGER = "DELETE_MANAGER";
export const DELETE_SOCIAL_LINK = "DELETE_SOCIAL_LINK";
export const UPDATE_SOCIAL_LINK = "UPDATE_SOCIAL_LINK";
export const ADD_SOCIAL_LINK = "ADD_SOCIAL_LINK";
export const GET_CONNECTIONS = "GET_CONNECTIONS";
export const GET_INBOUND_OUTBOUND = "GET_INBOUND_OUTBOUND";
export const GET_ACTIVE_USERS = "GET_ACTIVE_USERS";
