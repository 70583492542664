import {
  GET_PROFILE_VIEWS,
  GET_FAV_COUNT,
  GET_CONNECTIONS,
  GET_USER,
  GET_INBOUND_OUTBOUND,
  GET_ACTIVE_USERS,
} from "../Constant";
const intiialState = {
  profileData: {},
  loading: false,
  error: null,
  favData: {},
  connectionData: {},
  userData: null,
};
const dashboardReducer = (state = intiialState, action) => {
  switch (action.type) {
    case GET_PROFILE_VIEWS + "REQUEST":
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_VIEWS + "SUCCESS":
      return {
        ...state,
        loading: false,
        profileData: action.payload,
      };
    case GET_PROFILE_VIEWS + "FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_FAV_COUNT + "REQUEST":
      return {
        ...state,
        loading: true,
      };
    case GET_FAV_COUNT + "SUCCESS":
      return {
        ...state,
        loading: false,
        favData: action.payload,
      };
    case GET_FAV_COUNT + "FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CONNECTIONS + "REQUEST":
      return {
        ...state,
        loading: true,
      };
    case GET_CONNECTIONS + "SUCCESS":
      return {
        ...state,
        loading: false,
        connectionData: action.payload,
      };
    case GET_CONNECTIONS + "FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER + "REQUEST":
      return {
        ...state,
        loading: true,
      };
    case GET_USER + "SUCCESS":
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case GET_USER + "FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_INBOUND_OUTBOUND + "REQUEST":
      return {
        ...state,
        loading: true,
      };
    case GET_INBOUND_OUTBOUND + "SUCCESS":
      return {
        ...state,
        loading: false,
        inboundOutboundData: action.payload,
      };
    case GET_INBOUND_OUTBOUND + "FAILED":
    case GET_ACTIVE_USERS + "REQUEST":
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVE_USERS + "SUCCESS":
      return {
        ...state,
        loading: false,
        activeUsers: action.payload,
      };
    case GET_ACTIVE_USERS + "FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
