import React, { useState, useEffect } from "react";

// react plugin used to create charts
import { Bar } from "react-chartjs-2";
import classNames from "classnames";
import { useAlert } from "react-alert";

import { useDispatch } from "react-redux";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  ButtonGroup,
} from "reactstrap";
import { getInboundOutbound } from "actions/dashbaordAction";

const InboundOutbound = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  // let dashboardData = useSelector((state) => state.dashboardData);
  // const { inboundOutboundData } = dashboardData;
  const [bigChartData, setbigChartData] = React.useState("m");
  const [charData, setcharData] = useState(null);
  let filters = [
    {
      name: "Today",
      key: "t",
    },
    {
      name: "Weekly",
      key: "w",
    },
    {
      name: "Monthly",
      key: "m",
    },
    {
      name: "Till Now",
      key: "a",
    },
  ];
  const formateData = (type, incoming) => {
    let resp = { inbound: [], outbound: [], label: [] };
    if (type === "m" || type === "w") {
      let inbound = incoming.inbound.map((e) => e._id);
      let outbound = incoming.outbound.map((e) => e._id);
      let allLabels = [...new Set([...inbound, ...outbound].sort())].sort();
      resp.label = allLabels;
      for (let index = 0; index < allLabels.length; index++) {
        const element = allLabels[index];
        if (inbound.includes(element)) {
          let index = inbound.indexOf(element);
          resp.inbound.push(incoming.inbound[index].count);
        } else {
          resp.inbound.push(0);
        }
        if (outbound.includes(element)) {
          let index = outbound.indexOf(element);
          resp.outbound.push(incoming.outbound[index].count);
        } else {
          resp.outbound.push(0);
        }
      }
    } else {
      resp.inbound = [incoming?.inbound[0]?.chatCount ?? 0];
      resp.outbound = [incoming?.outbound[0]?.chatCount ?? 0];
      resp.label = [type === "a" ? "Till Now" : "Today"];
    }
    return resp;
  };

  const genRateChartData = (incoming) => {
    return {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
        gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
        gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

        return {
          labels: incoming.label,
          datasets: [
            {
              label: "Inbound",
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: "#1f8ef1",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: incoming.inbound,
            },
            {
              label: "Outbound",
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: "#d048b6",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: incoming.outbound,
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 120,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        },
      },
    };
  };
  useEffect(() => {
    dispatch(getInboundOutbound({ dateFilter: bigChartData, userId: null }))
      .then((resp) => {
        let formatedData = formateData(bigChartData, resp);
        let data = genRateChartData(formatedData);
        setcharData(data);
      })
      .catch((err) => {
        alert.error(typeof err.message === "string" ? err.message : "");
      });
  }, [bigChartData]);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col sm="6">
                    <h5 className="card-category">Inboubd/Outbound</h5>
                    <CardTitle tag="h3"></CardTitle>
                  </Col>
                  <Col sm="6">
                    {" "}
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      {filters.map((e) => {
                        return (
                          <Button
                            key={e.key}
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === e.key,
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setbigChartData(e.key)}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              {e.name}
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {charData && (
                  <div className="chart-area">
                    <Bar data={charData.data} options={charData.options} />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InboundOutbound;
