import axios from "axios";

import Config from "../Config.js";
import { GET_OTP, VERIFY_OTP } from "../Constant";

export const getOtp = (body) => async (dispatch) => {
  return new Promise(async (resolve, resject) => {
    try {
      console.log(body);
      dispatch({
        type: GET_OTP + "REQUEST",
      });
      let { data } = await axios.post(
        `${Config.BASE_URL}${Config.GET_OTP}`,
        body
      );

      if (data.status) {
        localStorage.setItem("checkbox", body.checkbox);
        if (body.checkbox) {
          localStorage.setItem("email", body.email);
          localStorage.setItem("password", body.password);
        }
        dispatch({
          type: GET_OTP + "SUCCESS",
          payload: data.data.user,
        });
        localStorage.setItem("user", JSON.stringify(data.data.user));
        localStorage.setItem("token", data.data.accessToken);
        resolve(data.data);
      } else {
        for (const key in data.message) {
          if (data.message[key]) {
            dispatch({
              type: GET_OTP + "FAILED",
              payload: data.message[key],
            });
            resject(new Error(data.message[key]));
            break;
          }
        }
      }
    } catch (error) {
      console.error(error);
      error.message = error?.response?.data?.message || "Network Issue";
      dispatch({
        type: GET_OTP + "FAILED",
        payload: error.message,
      });

      resject(error);
    }
  });
};

export const logOut = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      resolve("logOut");
    } catch (error) {
      reject(error);
    }
  });
};
