import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useAlert } from "react-alert";

import {
  Button,
  FormGroup,
  Form,
  Input,
  Col,
  Container,
  Label,
} from "reactstrap";
import loginBg from "../assets/img/console_bg.jpg";
import loginImage from "../assets/img/MUSICIDIA_main_white.svg";
import { getOtp, verifyOtp } from "actions/loginAction";

const Login = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  let loginData = useSelector((state) => state.loginData);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      navigation(`/admin/`);
    }
  }, [navigation]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    checkbox: false,
  });
  const [type, setType] = useState(0);

  useEffect(() => {}, [loginData]);

  useEffect(() => {
    let email = localStorage.getItem("email");
    let password = localStorage.getItem("password");
    let checkbox = localStorage.getItem("checkbox");
    setFormData({
      ...formData,
      password,
      email,
      checkbox: JSON.parse(checkbox),
    });
  }, []);
  const handleInputChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(getOtp(formData))
      .then((resp) => {
        navigation(`/admin/`);
        alert.success(`Login successfully`);
      })
      .catch((err) => {
        alert.error(typeof err.message === "string" ? err.message : "");
      });
  };
  return (
    <>
      <div style={{ padding: "0", overflow: "none", display: "flex" }}>
        <Col
          style={{
            margin: "20px",
            height: "calc(100vh - 40px)",
            borderRadius: "12px",
            backgroundImage: `url(${loginBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            display: "flex",
            justifyConten: "center",
            alignItems: "center",
          }}
          md="4"
          className="d-flex justify-content-center align-content-center"
        >
          <img style={{ width: "50%" }} src={loginImage} alt="react-logo" />
        </Col>
        <Col
          md="8"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container>
            <h2>Get started</h2>

            <Form onSubmit={handleSubmit}>
              <FormGroup
                style={{
                  width: "500px",
                }}
              >
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required={true}
                />
              </FormGroup>
              <FormGroup
                style={{
                  width: "500px",
                }}
              >
                <Label for="password">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required={true}
                />
              </FormGroup>
              <FormGroup
                style={{
                  width: "500px",
                }}
              >
                <Label for=" ">Remember me</Label>
                <Input
                  className="form-group form-control"
                  type="checkbox"
                  name="checkbox"
                  value={formData.checkbox}
                  checked={formData.checkbox ? true : false}
                  onChange={(e) =>
                    setFormData({ ...formData, checkbox: e.target.checked })
                  }
                />
              </FormGroup>
              <br />
              <Button color="info">Login</Button>
            </Form>
          </Container>
        </Col>
      </div>
    </>
  );
};

export default Login;
